import React, {useState} from 'react'

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import routes from "../../routes";
import DefaultNavbar from "../navigation/DefaultNavbar";
import {submitContactInformation} from "../../gateway/HomepageGateway";
import footerRoutes from "../footer/footer.routes";
import {useMutation} from "@tanstack/react-query";
import {Dialog, DialogActions, DialogTitle} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DefaultFooter from "../footer/Footer";
import Products from "../home/products/Products";

function Shop() {
    const [username, setUsername] = useState()
    const [email, setEmail] = useState()
    const [message, setMessage] = useState()
    const [isUsernameInvalid, setIsUsernameInvalid] = useState()
    const [isEmailInvalid, setIsEmailInvalid] = useState()
    const [isMessageInvalid, setIsMessageInvalid] = useState()
    const mutation = useMutation({mutationFn: submitContactInformation})
    const shouldSubmitButtonDisable = !username || !email || !message || isUsernameInvalid || isEmailInvalid || isMessageInvalid || mutation.isLoading
    const usernameRegex = /^[a-zA-Z0-9 ]+$/;
    const emailRegex = /\S+@\S+\.\S+/;
    const messageRegex = /^[a-zA-Z0-9,.!()? _:]+$/;

    const onCloseSuccessDialog = () => {
        if (mutation.isSuccess) {
            setUsername("")
            setEmail("")
            setMessage("")
        }
        mutation.reset()
    };
    return (
        <>
            <Dialog open={mutation.isSuccess} onClose={onCloseSuccessDialog}>
                <DialogTitle>
                    <MKBox display="flex" alignItems="center" justifyContent="start" p={1}>
                        <CheckCircleIcon fontSize='large' color='success' sx={{mr: 1}}/>
                        <MKTypography variant="h4">Success</MKTypography>
                    </MKBox>
                </DialogTitle>
                <MKBox
                    position="relative"
                    width="400px"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    sx={{p: 1, ml: 2, mr: 2}}
                >
                    <MKTypography variant="body1" opacity={0.8} mt={1} mb={1}>
                        Thank you for reaching out! Your message has been delivered. We will response to you within 48
                        hours.
                    </MKTypography>
                </MKBox>
                <DialogActions>
                    <MKButton color="info" onClick={onCloseSuccessDialog}>
                        OK
                    </MKButton>
                </DialogActions>
            </Dialog>
            <Dialog open={mutation.error} onClose={() => {
                mutation.reset()
            }}>
                <DialogTitle>
                    <MKBox display="flex" alignItems="center" justifyContent="start" p={1}>
                        <CancelIcon fontSize='large' color='error' sx={{mr: 1}}/>
                        <MKTypography variant="h4">Error</MKTypography>
                    </MKBox>
                </DialogTitle>
                <MKBox
                    position="relative"
                    width="400px"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    sx={{p: 1, ml: 2, mr: 2}}
                >
                    <MKTypography variant="body1" opacity={0.8} mt={1} mb={1}>
                        An unexpected error occurred. Please try again later.
                    </MKTypography>
                </MKBox>
                <DialogActions>
                    <MKButton color="info" onClick={onCloseSuccessDialog}>
                        OK
                    </MKButton>
                </DialogActions>
            </Dialog>
            <MKBox bgColor="white" shadow="sm" py={0.25}>
                <DefaultNavbar
                    routes={routes}
                    transparent
                    relative
                />
            </MKBox>
            <Products/>
            <DefaultFooter content={footerRoutes}/>
        </>

    );
}

export default Shop;
