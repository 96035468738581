export default {
    styleOverrides: {
        root: {
            '&:hover': {
                backgroundColor: "#daefda",
            },
        },
        colorSuccess: {
            backgroundColor: "#daefda",
        }
    },
};
