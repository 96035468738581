import {styled} from "@mui/material/styles";
import {DateTimePicker} from "@mui/x-date-pickers";

export default styled(DateTimePicker)(({ theme, ownerState }) => {
  const {palette} = theme;
  const {error, success, disabled} = ownerState;

  const {grey, error: colorError, success: colorSuccess} = palette;

  // styles for the input with error={true}
  const errorStyles = () => ({
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline, &:after": {
        borderColor: colorError.main,
      },
    },

    "& .MuiInputLabel-root.Mui-focused": {
      color: colorError.main,
    },
  });

  // styles for the input with success={true}
  const successStyles = () => ({
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline, &:after": {
        borderColor: colorSuccess.main,
      },
    },

    "& .MuiInputLabel-root.Mui-focused": {
      color: colorSuccess.main,
    },
  });

  return {
    backgroundColor: disabled ? `${grey[200]} !important` : palette.primary,
    pointerEvents: disabled ? "none" : "auto",
    ...(error && errorStyles()),
    ...(success && successStyles()),
  };
});
