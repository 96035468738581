import Card from "@mui/material/Card";
import React from 'react'
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Carousel from "react-material-ui-carousel";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

function ImageCard({images, description}) {
    return (
        <>
            <Card sx={{
                background: "transparent",
                boxShadow: "none",
                overflow: "visible",
                height: "350px",
            }}>
                <Carousel
                    autoPlay={false}
                    indicators={false}
                    navButtonsAlwaysVisible={false}
                    NextIcon={<NavigateNextIcon fontSize='small'/>}
                    PrevIcon={<NavigateBeforeIcon fontSize='small'/>}
                    sx={{
                        overflow: "visible",
                        height: "300px",
                    }}
                >
                    {
                        images.map((image) => {
                            return <MKBox position="relative" borderRadius="lg">
                                <MKBox
                                    component="img"
                                    src={image}
                                    alt={description}
                                    borderRadius="lg"
                                    width="100%"
                                    position="relative"
                                    zIndex={1}
                                    sx={{
                                        height: "300px",
                                        objectFit: "cover"
                                    }}
                                />
                            </MKBox>

                        })
                    }
                </Carousel>
                <MKBox p={1} mt={-1} textAlign="center">
                    <MKBox mt={1} mb={1}>
                        <MKTypography variant="body2" component="p" color="text">
                            {description}
                        </MKTypography>
                    </MKBox>
                </MKBox>
            </Card>
        </>
    );
}

export default ImageCard;
