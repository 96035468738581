import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React, {useState} from 'react'

import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import bgImage from "assets/images/examples/contactbackground.jpg";
import routes from "../../routes";
import DefaultNavbar from "../navigation/DefaultNavbar";
import {submitContactInformation} from "../../gateway/HomepageGateway";
import {Link} from "react-router-dom";
import footerRoutes, {ebayShop, etsyShop} from "../footer/footer.routes";
import {useMutation} from "@tanstack/react-query";
import {Dialog, DialogActions, DialogTitle} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DefaultFooter from "../footer/Footer";

function Contact() {
    const [username, setUsername] = useState()
    const [email, setEmail] = useState()
    const [message, setMessage] = useState()
    const [isUsernameInvalid, setIsUsernameInvalid] = useState()
    const [isEmailInvalid, setIsEmailInvalid] = useState()
    const [isMessageInvalid, setIsMessageInvalid] = useState()
    const mutation = useMutation({mutationFn: submitContactInformation})
    const shouldSubmitButtonDisable = !username || !email || !message || isUsernameInvalid || isEmailInvalid || isMessageInvalid || mutation.isLoading
    const usernameRegex = /^[a-zA-Z0-9 ]+$/;
    const emailRegex = /\S+@\S+\.\S+/;
    const messageRegex = /^[a-zA-Z0-9,.!()? _:]+$/;

    const onCloseSuccessDialog = () => {
        if (mutation.isSuccess) {
            setUsername("")
            setEmail("")
            setMessage("")
        }
        mutation.reset()
    };
    return (
        <>
            <Dialog open={mutation.isSuccess} onClose={onCloseSuccessDialog}>
                <DialogTitle>
                    <MKBox display="flex" alignItems="center" justifyContent="start" p={1}>
                        <CheckCircleIcon fontSize='large' color='success' sx={{mr: 1}}/>
                        <MKTypography variant="h4">Success</MKTypography>
                    </MKBox>
                </DialogTitle>
                <MKBox
                    position="relative"
                    width="400px"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    sx={{p: 1, ml: 2, mr: 2}}
                >
                    <MKTypography variant="body1" opacity={0.8} mt={1} mb={1}>
                        Thank you for reaching out! Your message has been delivered. We will response to you within 48
                        hours.
                    </MKTypography>
                </MKBox>
                <DialogActions>
                    <MKButton color="info" onClick={onCloseSuccessDialog}>
                        OK
                    </MKButton>
                </DialogActions>
            </Dialog>
            <Dialog open={mutation.error} onClose={() => {
                mutation.reset()
            }}>
                <DialogTitle>
                    <MKBox display="flex" alignItems="center" justifyContent="start" p={1}>
                        <CancelIcon fontSize='large' color='error' sx={{mr: 1}}/>
                        <MKTypography variant="h4">Error</MKTypography>
                    </MKBox>
                </DialogTitle>
                <MKBox
                    position="relative"
                    width="400px"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    sx={{p: 1, ml: 2, mr: 2}}
                >
                    <MKTypography variant="body1" opacity={0.8} mt={1} mb={1}>
                        An unexpected error occurred. Please try again later.
                    </MKTypography>
                </MKBox>
                <DialogActions>
                    <MKButton color="info" onClick={onCloseSuccessDialog}>
                        OK
                    </MKButton>
                </DialogActions>
            </Dialog>
            <MKBox bgColor="white" shadow="sm" py={0.25}>
                <DefaultNavbar
                    routes={routes}
                    transparent
                    relative
                />
            </MKBox>
            <Container sx={{mt: 12}}>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    flexDirection="column"
                    alignItems="center"
                    sx={{textAlign: "center", my: 6, mx: "auto", px: 0.75}}
                >
                    <MKTypography variant="h2" fontWeight="bold">
                        Contact Us
                    </MKTypography>
                    <MKTypography variant="body1" color="text">

                    </MKTypography>
                </Grid>
            </Container>
            <MKBox component="section" py={{xs: 0, lg: 6}}>
                <Container>
                    <Grid container item>
                        <MKBox
                            width="100%"
                            bgColor="white"
                            borderRadius="xl"
                            shadow="xl"
                            mb={6}
                            sx={{overflow: "hidden"}}
                        >
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={5}
                                    position="relative"
                                    px={0}
                                    sx={{
                                        backgroundImage: ({
                                                              palette: {gradients},
                                                              functions: {rgba, linearGradient},
                                                          }) =>
                                            `${linearGradient(
                                                rgba(gradients.dark.main, 0.4),
                                                rgba(gradients.dark.state, 0.4)
                                            )}, url(${bgImage})`,
                                        backgroundSize: "cover",
                                    }}
                                >
                                    <MKBox
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        width="100%"
                                        height="100%"
                                        mt={8}
                                    >
                                        <MKBox py={6} pr={6} pl={{xs: 6, sm: 12}} my="auto">
                                            <MKTypography variant="h3" color="white" mb={1}>
                                                Contact Information
                                            </MKTypography>
                                            <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                                                Fill up the form and we will get back to you within 24 hours.
                                            </MKTypography>
                                            {/*<MKBox display="flex" p={1}>*/}
                                            {/*    <MKTypography variant="button" color="white">*/}
                                            {/*        <i className="fas fa-phone"/>*/}
                                            {/*    </MKTypography>*/}
                                            {/*    <MKTypography*/}
                                            {/*        component="span"*/}
                                            {/*        variant="button"*/}
                                            {/*        color="white"*/}
                                            {/*        opacity={0.8}*/}
                                            {/*        ml={2}*/}
                                            {/*        fontWeight="regular"*/}
                                            {/*    >*/}
                                            {/*        (+40) 772 100 200*/}
                                            {/*    </MKTypography>*/}
                                            {/*</MKBox>*/}
                                            <MKBox display="flex" color="white" p={1}>
                                                <MKTypography variant="button" color="white">
                                                    <i className="fa-solid fa-at"/>
                                                </MKTypography>
                                                <MKTypography
                                                    component="span"
                                                    variant="button"
                                                    color="white"
                                                    opacity={0.8}
                                                    ml={2}
                                                    fontWeight="regular"
                                                >
                                                    jaicee.us@gmail.com
                                                </MKTypography>
                                            </MKBox>
                                            <MKBox display="flex" color="white" p={1}>
                                                <MKTypography variant="button" color="white">
                                                    <i className="fas fa-map-marker-alt"/>
                                                </MKTypography>
                                                <MKTypography
                                                    component="span"
                                                    variant="button"
                                                    color="white"
                                                    opacity={0.8}
                                                    ml={2}
                                                    fontWeight="regular"
                                                >
                                                    Country Club View, Fairfax, VA
                                                </MKTypography>
                                            </MKBox>
                                            <MKBox display="flex" color="white" p={1}>
                                                <MKTypography variant="button" color="white">
                                                    <i className="fa-solid fa-envelope"/>
                                                </MKTypography>
                                                <MKTypography
                                                    component="span"
                                                    variant="button"
                                                    color="white"
                                                    opacity={0.8}
                                                    ml={2}
                                                    fontWeight="regular"
                                                >
                                                    SMS: 5714619720
                                                </MKTypography>
                                            </MKBox>
                                            <MKBox display="flex" color="white" p={1}>
                                                <MKTypography variant="button" color="white">
                                                    <i className="fa-brands fa-weixin"/>
                                                </MKTypography>
                                                <MKTypography
                                                    component="span"
                                                    variant="button"
                                                    color="white"
                                                    opacity={0.8}
                                                    ml={2}
                                                    fontWeight="regular"
                                                >
                                                    WeChat: cocka_co
                                                </MKTypography>
                                            </MKBox>
                                            <MKBox mt={3}>
                                                <MKButton component={Link} to={ebayShop} target="_blank" variant="text"
                                                          color="white" size="large" iconOnly>
                                                    <i className="fa-brands fa-ebay" style={{fontSize: "2rem"}}/>
                                                </MKButton>
                                                <MKButton component={Link} to={etsyShop} target="_blank" variant="text"
                                                          color="white" size="large" iconOnly>
                                                    <i className="fa-brands fa-etsy" style={{fontSize: "1rem"}}/>
                                                </MKButton>
                                            </MKBox>
                                        </MKBox>
                                    </MKBox>
                                </Grid>
                                <Grid item xs={12} lg={7}>
                                    <MKBox component="form" p={2} method="post">
                                        <MKBox px={3} py={{xs: 2, sm: 6}}>
                                            <MKTypography variant="h2" mb={1}>
                                                Say Hi!
                                            </MKTypography>
                                            <MKTypography variant="body1" color="text" mb={2}>
                                                We&apos;d like to talk with you.
                                            </MKTypography>
                                        </MKBox>
                                        <MKBox pt={0.5} pb={3} px={3}>
                                            <Grid container>
                                                <Grid item xs={12} pr={1} mb={6}>
                                                    <MKInput
                                                        variant="standard"
                                                        label="Name"
                                                        placeholder=""
                                                        InputLabelProps={{shrink: true}}
                                                        fullWidth
                                                        value={username}
                                                        onChange={(event) => {
                                                            setUsername(event.target.value);
                                                            setIsUsernameInvalid(false)
                                                        }}
                                                        error={isUsernameInvalid}
                                                        helperText={isUsernameInvalid ? "only letters and numbers are allowed in name" : ""}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} pr={1} mb={6}>
                                                    <MKInput
                                                        variant="standard"
                                                        label="Email"
                                                        placeholder=""
                                                        InputLabelProps={{shrink: true}}
                                                        fullWidth
                                                        value={email}
                                                        onChange={(event) => {
                                                            setEmail(event.target.value);
                                                            setIsEmailInvalid(false)
                                                        }}
                                                        error={isEmailInvalid}
                                                        helperText={isEmailInvalid ? "invalid email address" : ""}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} pr={1} mb={6}>
                                                    <MKInput
                                                        variant="standard"
                                                        label="Your message"
                                                        placeholder="I want to say that..."
                                                        InputLabelProps={{shrink: true}}
                                                        fullWidth
                                                        multiline
                                                        rows={6}
                                                        value={message}
                                                        onChange={(event) => {
                                                            setMessage(event.target.value);
                                                            setIsMessageInvalid(false)
                                                        }}
                                                        error={isMessageInvalid}
                                                        helperText={isMessageInvalid ? "invalid character in message" : ""}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                md={6}
                                                justifyContent="flex-end"
                                                textAlign="right"
                                                ml="auto"
                                            >
                                                <MKButton variant="gradient" color="info"
                                                          disabled={shouldSubmitButtonDisable} onClick={() => {
                                                    if (!username.match(usernameRegex)) {
                                                        setIsUsernameInvalid(true)
                                                    } else if (!email.match(emailRegex)) {
                                                        setIsEmailInvalid(true)
                                                    } else if (!message.match(messageRegex)) {
                                                        setIsMessageInvalid(true)
                                                    } else {
                                                        mutation.mutate({username, email, message})
                                                    }
                                                }}>
                                                    Send Message
                                                </MKButton>
                                            </Grid>
                                        </MKBox>
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </MKBox>
                    </Grid>
                </Container>
            </MKBox>
            <DefaultFooter content={footerRoutes}/>
        </>

    );
}

export default Contact;
