import PropTypes from "prop-types";
import React from 'react'
import MKBox from "components/MKBox";

function View({ children, height, ...rest }) {

  return (
    <MKBox
      width="100%"
      position="relative"
      borderRadius="xl"
      shadow="lg"
      mb={12}
      sx={{ overflow: "hidden" }}
      {...rest}
    >
      <MKBox>
        <MKBox width="100%" p={3}>
          <MKBox
            bgColor="grey-100"
            width="100%"
            height={height}
            maxHeight="60rem"
            borderRadius="xl"
            sx={{ overflowX: "hidden", overflowY: "hidden" }}
          >
            {children}
          </MKBox>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

// Setting default props for the View
View.defaultProps = {
  height: "auto",
};

// Typechecking props for the View
View.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
};

export default View;
