import './App.css';
import React, {useEffect} from 'react';
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from "./assets/theme";
import {Route, Routes, useLocation} from "react-router-dom";
import routes from "routes";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const queryClient = new QueryClient()

function App() {
    const {pathname} = useLocation();

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key}/>;
            }

            return null;
        });

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Routes>
                    {getRoutes(routes)}
                </Routes>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
