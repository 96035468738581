import MKBox from "../../../components/MKBox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "../../../components/MKTypography";
import Stack from "@mui/material/Stack";
import {ListItem} from "@mui/material";
import React from 'react'

export default function SmallAnimalBoardingRules() {
    return <MKBox component="section" py={{xs: 3, md: 3}} sx={{ml: 4}}>
        <Container>
            <Grid
                container
                item
                xs={12}
                lg={6}
                flexDirection="column"
                alignItems="center"
                sx={{textAlign: "center", my: 6, mx: "auto", px: 0.75}}
            >
                <MKTypography variant="h2" fontWeight="bold">
                    Rules and Conditions in Cocka
                </MKTypography>
            </Grid>
            <Stack>
                <MKBox display="flex" alignItems="center" p={2}>
                    <Stack>
                        <MKTypography variant="h4" my={1}>
                            Small Animal
                        </MKTypography>
                        <ListItem sx={{padding: 1, display: "list-item",}}>
                            Thus contumely, the will, and scorns of thousand natural shocks that sleep; to sleep to
                            sleep to say we end the unworthy takes
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item"}}>
                            rule2
                        </ListItem>
                    </Stack>
                </MKBox>
                <MKBox display="flex" alignItems="center" p={2}>
                    <Stack>
                        <MKTypography variant="h4" my={1}>
                            Keep the house quiet
                        </MKTypography>
                        <ListItem sx={{padding: 1, display: "list-item",}}>
                            rule1
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item"}}>
                            rule2
                        </ListItem>
                    </Stack>
                </MKBox>
            </Stack>
        </Container>
    </MKBox>;
}