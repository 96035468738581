import shower from "../../../../assets/images/icon/shower.png";
import paw from "../../../../assets/images/icon/paw.png";
import Teeth from "../../../../assets/images/icon/teeth.png";
import brushing from "../../../../assets/images/icon/brushing.png";
import fetch from "../../../../assets/images/icon/fetch.png";
import neighborhood from "../../../../assets/images/icon/neighborhood.png";
import park from "../../../../assets/images/icon/park.png";
import trail from "../../../../assets/images/icon/trail.png";
import PickDrop from "../../../../assets/images/icon/PickDrop.png";


export const extraServicesData = [
    {icon: shower,
        name: "An extra photo or video update",
        smallPrice: "$5",
        largePrice: "$5"
    },
    {icon: shower,
        name: "10min Video chat with your dog",
        smallPrice: "$5",
        largePrice: "$5"
    },
    {
        icon: fetch,
        name: "15 min fetch and ball games",
        smallPrice: "$5",
        largePrice: "$5"
    },
    {
        icon: neighborhood,
        name: "20 Min neighborhood walk",
        smallPrice: "$5",
        largePrice: "$5"
    },
    {
        icon: park,
        name: "40 Min Trail walk through the park",
        smallPrice: "$10",
        largePrice: "$10"
    },
    {
        icon: Teeth,
        name: "Teeth brushing",
        smallPrice: "$5",
        largePrice: "$10"
    },
    {
        icon: brushing,
        name: "Dog brushing",
        smallPrice: "$5",
        largePrice: "$10"
    },
    {
        icon: paw,
        name: "Nail trim",
        smallPrice: "$10",
        largePrice: "$15"
    },
    {
        icon: shower,
        name: "Bath",
        smallPrice: "$20",
        largePrice: "$30"
    },
    {
        icon: trail,
        name: "Two hours trail walk at national park",
        smallPrice: "$50",
        largePrice: "$70"
    },
    {
        icon: PickDrop,
        name: "Pick up/Drop off service (Fairfax county)",
        smallPrice: "$20 for the first 10 miles",
        largePrice: "$1 for each additional mile"
    },
]