export async function submitContactInformation({username, email, message}) {
    const csrfToken = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
    const requestBody = {
        "name": username,
        "email": email,
        "message": message
    };
    const response = await fetch(`${window.location.origin}/api/contact`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
            "Content-Type": "application/json",
            "X-XSRF-TOKEN": csrfToken
        },
    })
    if (response.status === 200) {
        return;
    }
    throw Error("Fail to submit. Please try again later")
}

export async function submitBoardingRequest(requestBody) {
    const csrfToken = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
    const response = await fetch(`${window.location.origin}/api/boarding/submit`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
            "Content-Type": "application/json",
            "X-XSRF-TOKEN": csrfToken
        },
    })
    if (response.status === 200) {
        return;
    }
    throw Error("Fail to submit. Please try again later")
}
