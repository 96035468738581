import PropTypes from "prop-types";

import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKButton from "../../components/MKButton";
import React, {useState} from "react";
import {Autocomplete, Dialog, DialogActions, DialogTitle, Modal, useMediaQuery, useTheme} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import MKInput from "../../components/MKInput";
import Grid from "@mui/material/Grid";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import MKDateTimePicker from "../../components/MKDateTimePicker";
import PetsIcon from '@mui/icons-material/Pets';
import {useMutation} from "@tanstack/react-query";
import {submitBoardingRequest} from "../../gateway/HomepageGateway";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from '@mui/icons-material/Check';

function PricingCard({variant, color, bgColor, title, price, points, categories, animal, unit}) {
    const planName = title
    const [show, setShow] = useState(false);
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [petName, setPetName] = useState();
    const [age, setAge] = useState();
    const [weight, setWeight] = useState();
    const [animalType, setAnimalType] = useState();
    const [otherAnimalType, setOtherAnimalType] = useState();
    const [startDateTime, setStartDateTime] = useState();
    const [endDateTime, setEndDateTime] = useState();
    const [notes, setNotes] = useState();
    const mutation = useMutation({mutationFn: submitBoardingRequest})
    const toggleModal = () => setShow(!show);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const fullWidth = useMediaQuery(theme.breakpoints.down('md'));

    const onCloseSuccessDialog = () => {
        mutation.reset()
        setShow(!show)
        setFirstName("")
        setLastName("")
        setEmail("")
        setPhoneNumber("")
        setPetName("")
        setAge("")
        setWeight("")
        setAnimalType("")
        setOtherAnimalType("")
        setStartDateTime("")
        setEndDateTime("")
        setNotes("")
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog open={mutation.isSuccess} onClose={onCloseSuccessDialog}>
                <DialogTitle>
                    <MKBox display="flex" alignItems="center" justifyContent="start" p={1}>
                        <CheckCircleIcon fontSize='large' color='success' sx={{mr: 1}}/>
                        <MKTypography variant="h4">Success</MKTypography>
                    </MKBox>
                </DialogTitle>
                <MKBox
                    position="relative"
                    width="400px"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    sx={{p: 1, ml: 2, mr: 2}}
                >
                    <MKTypography variant="body1" opacity={0.8} mt={1} mb={1}>
                        Your boarding request has been submitted successfully. We will response to you within 24
                        hours. Thank you for your business.
                    </MKTypography>
                </MKBox>
                <DialogActions>
                    <MKButton color="info" onClick={onCloseSuccessDialog}>
                        OK
                    </MKButton>
                </DialogActions>
            </Dialog>
            <Dialog open={mutation.error} onClose={() => {mutation.reset()}}>
                <DialogTitle>
                    <MKBox display="flex" alignItems="center" justifyContent="start" p={1}>
                        <CancelIcon fontSize='large' color='error' sx={{mr: 1}}/>
                        <MKTypography variant="h4">Error</MKTypography>
                    </MKBox>
                </DialogTitle>
                <MKBox
                    position="relative"
                    width="400px"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    sx={{p: 1, ml: 2, mr: 2}}
                >
                    <MKTypography variant="body1" opacity={0.8} mt={1} mb={1}>
                        An unexpected error occurred. Please try again later.
                    </MKTypography>
                </MKBox>
                <DialogActions>
                    <MKButton color="info" onClick={() => {mutation.reset()}}>
                        OK
                    </MKButton>
                </DialogActions>
            </Dialog>
            <Modal open={show} onClose={toggleModal} sx={{display: "grid", placeItems: "center", overflow: "auto"}}
                   fullScreen={fullScreen} fullWidth>
                <MKBox
                    position="relative"
                    width={fullWidth ? undefined: "500px"}
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                >
                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                        <MKBox display="flex" alignItems="center" justifyContent="start" p={1}>
                            <PetsIcon fontSize='medium' sx={{mr: 1}}/>
                            <MKTypography variant="h4">Submit boarding request</MKTypography>
                        </MKBox>
                        <CloseIcon fontSize="medium" sx={{cursor: "pointer"}} onClick={toggleModal}/>
                    </MKBox>
                    <Divider sx={{my: 0}}/>
                    <Grid container item xs={12} sx={{mx: "auto"}}>
                        <MKBox width="100%" component="form" method="post">
                            <MKBox p={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <MKTypography variant="h5">Basic Information</MKTypography>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <MKInput variant="standard" label="First Name" fullWidth required
                                                 value={firstName}
                                                 onChange={(event) => {
                                                     setFirstName(event.target.value);
                                                 }}/>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <MKInput variant="standard" label="Last Name" fullWidth required
                                                 value={lastName}
                                                 onChange={(event) => {
                                                     setLastName(event.target.value);
                                                 }}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MKInput variant="standard" type="email" label="Email Address" fullWidth
                                                 required
                                                 value={email}
                                                 onChange={(event) => {
                                                     setEmail(event.target.value);
                                                 }}/>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <MKInput variant="standard" type="tel" label="Phone number" fullWidth required
                                                 value={phoneNumber}
                                                 onChange={(event) => {
                                                     setPhoneNumber(event.target.value);
                                                 }}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MKTypography variant="h5">Pet Information</MKTypography>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <MKInput variant="standard" label="Pet Name" fullWidth required
                                                 value={petName}
                                                 onChange={(event) => {
                                                     setPetName(event.target.value);
                                                 }}/>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <MKInput variant="standard" type="number" label="Age" fullWidth required
                                                 value={age}
                                                 onChange={(event) => {
                                                     setAge(event.target.value);
                                                 }}
                                                 inputProps={{
                                                     max: 99,
                                                     min: 0,
                                                     step: 1,
                                                 }}/>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <MKInput variant="standard" label={`Weight (${unit})`} type="number"
                                                 value={weight}
                                                 onChange={(event) => {
                                                     setWeight(event.target.value);
                                                 }}
                                                 inputProps={{
                                                     min: 0,
                                                     step: 0.5,
                                                 }} fullWidth/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            disablePortal
                                            options={categories}
                                            value={animalType}
                                            onChange={(event, newValue) => {
                                                setAnimalType(newValue);
                                            }}
                                            sx={{width: 200}}
                                            renderInput={(params) =>
                                                <MKInput required variant="standard" {...params}
                                                         label={animal + " Type"}/>}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MKInput variant="standard" label="If other" fullWidth
                                                 value={otherAnimalType}
                                                 onChange={(event) => {
                                                     setOtherAnimalType(event.target.value);
                                                 }}/>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <MKTypography variant="h5">Boarding</MKTypography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MKDateTimePicker label="Start Time"
                                                          value={startDateTime}
                                                          onChange={(newValue) => {
                                                              setStartDateTime(newValue);
                                                          }}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MKDateTimePicker label="End Time"
                                                          value={endDateTime}
                                                          onChange={(newValue) => {
                                                              setEndDateTime(newValue);
                                                          }}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MKInput variant="standard" label="Notes" multiline fullWidth rows={3}
                                                 value={notes}
                                                 onChange={(event) => {
                                                     setNotes(event.target.value);
                                                 }}/>
                                    </Grid>
                                </Grid>
                            </MKBox>
                        </MKBox>
                    </Grid>
                    <Divider sx={{my: 0}}/>
                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                        <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                            close
                        </MKButton>
                        <MKButton variant="gradient" color="info" onClick={() => mutation.mutate(
                            {
                                firstName,
                                lastName,
                                email,
                                phoneNumber,
                                petName,
                                age,
                                weight,
                                animalType,
                                otherAnimalType,
                                startDateTime,
                                endDateTime,
                                notes,
                                animal,
                                unit,
                                planName
                            })}>
                            Submit
                        </MKButton>
                    </MKBox>
                </MKBox>
            </Modal>
            <MKBox
                display={{xs: "block", md: "flex"}}
                variant={variant}
                bgColor={bgColor}
                borderRadius="xl"
                pt={3.5}
                pb={3}
                px={1}
            >
                <MKBox pt={{xs: 3, md: 0}} pl={{xs: 0, md: 2}} lineHeight={1}>
                    <MKBox display="flex" alignItems="center" justifyContent="start">
                        <MKTypography
                            display="block"
                            variant="5"
                            color={variant === "contained" || color === "light" ? "dark" : "white"}
                            fontWeight="bold"
                            mb={1}
                        >
                            {title}
                        </MKTypography>
                    </MKBox>
                    <MKTypography
                        display="h3"
                        variant="h2"
                        color={variant === "contained" || color === "light" ? "dark" : "white"}
                        fontWeight="bold"
                        mb={1}
                    >
                        {price}
                    </MKTypography>
                    <MKBox
                        pt={1}
                        pb={3}
                    >
                        {points.map((point) => {
                            return <MKBox display="flex" alignItems="center" justifyContent="start" p={0.5}>
                                <CheckIcon color='success' sx={{mr: 1}}/>
                                <MKTypography
                                    opacity={1}
                                    variant="body2"
                                >
                                    {point}
                                </MKTypography>
                            </MKBox>
                        })}
                    </MKBox>
                    <MKButton color="dark" onClick={toggleModal}>
                        Schedule a Meet&Greet
                    </MKButton>
                </MKBox>
            </MKBox>
        </LocalizationProvider>
    );
}

// Setting default props for the FilledInfoCard
PricingCard.defaultProps = {
    variant: "contained",
    color: "info",
    action: false,
};

// Typechecking props for the FilledInfoCard
PricingCard.propTypes = {
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    title: PropTypes.string.isRequired,
    action: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            type: PropTypes.oneOf(["external", "internal"]).isRequired,
            route: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ]),
};

export default PricingCard;
