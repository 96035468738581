import MKBox from "../../components/MKBox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "../../components/MKTypography";
import Stack from "@mui/material/Stack";
import React from 'react'

export default function BoardingFAQ({faqs}) {
    return <MKBox component="section" py={{xs: 3, md: 3}} sx={{ml: 4}}>
        <Container>
            <Grid
                container
                item
                xs={12}
                lg={6}
                flexDirection="column"
                alignItems="center"
                sx={{textAlign: "center", my: 6, mx: "auto", px: 0.75}}
            >
                <MKTypography variant="h2" fontWeight="bold">
                    Frequently Asked Questions
                </MKTypography>
            </Grid>
            <Stack>
                {faqs.map((faq) => {
                    return <MKBox display="flex" alignItems="center" p={2}>
                        <Stack>
                            <MKTypography variant="h4" my={1}>
                                {faq.question}
                            </MKTypography>
                            <MKTypography variant="body2" color="text">
                                {faq.answer}
                            </MKTypography>
                        </Stack>
                    </MKBox>
                })}
            </Stack>
        </Container>
    </MKBox>;
}