import {Link} from "react-router-dom";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ProductCard from "./ProductCard";
import React from 'react'
import {productData} from "./productsData";

export default function Products() {
    const renderData = productData.map(({title, description, items}) => (
        <Grid container spacing={3} sx={{mb: 10}} key={title}>
            <Grid item xs={12} lg={3}>
                <MKBox position="sticky" top="100px" pb={{xs: 2, lg: 6}}>
                    <MKTypography variant="h3" fontWeight="bold" mb={1}>
                        {title}
                    </MKTypography>
                    <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                        {description}
                    </MKTypography>
                </MKBox>
            </Grid>
            <Grid item xs={12} lg={9}>
                <Grid container spacing={3}>
                    {items.map(({image, name, count, route, local, description}) => (
                        <Grid item xs={12} md={6} sx={{mb: 2}} key={name}>
                            <Link to={route}>
                                <ProductCard image={image} name={name} count={count} local={local}
                                             description={description}/>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    ));

    return (
        <>
            <MKBox id="products" component="section" my={6} py={6}>
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={6}
                        flexDirection="column"
                        alignItems="center"
                        sx={{textAlign: "center", my: 6, mx: "auto", px: 0.75}}
                    >
                        <MKTypography variant="h2" fontWeight="bold">
                            Hot Sellers
                        </MKTypography>
                        <MKTypography variant="body1" color="text">

                        </MKTypography>
                    </Grid>
                </Container>
                <Container sx={{mt: 6}}>{renderData}</Container>
            </MKBox>
        </>
    );
}