import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import DefaultInfoCard from "../information/InfoCards/DefaultInfoCard";
import MKTypography from "../../components/MKTypography";
import React from 'react'

export default function BoardingInformation() {
    return (
        <Container >
            <Grid
                container
                item
                xs={12}
                lg={6}
                flexDirection="column"
                alignItems="center"
                sx={{textAlign: "center", mb: 6, mt: 8, mx: "auto", px: 0.75}}
            >
                <MKTypography variant="h2" fontWeight="bold">
                    Why choosing Cocka
                </MKTypography>
            </Grid>
            <Grid container item xs={11} spacing={3} alignItems="center" sx={{mx: "auto"}}>
                <Grid item xs={12} lg={7} sx={{ml: "auto"}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <DefaultInfoCard
                                icon="school"
                                title="Experienced Sitters"
                                description="We have more than 20 years experiences for parrots and bunnies."
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DefaultInfoCard
                                icon="mood"
                                title="Satisfied Guarantee"
                                description="Recurring clients."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} sx={{mt: {xs: 0, md: 6}}}>
                        <Grid item xs={12} md={6}>
                            <DefaultInfoCard
                                icon="price_change"
                                title="Save Time & Money"
                                description="You don't have to compare with other sitters."
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DefaultInfoCard
                                icon="message"
                                title="Responsive"
                                description="Reliable sitters."
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}