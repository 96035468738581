import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import routes from "routes";
import DefaultFooter from "../footer/Footer";
import footerRoutes from "../footer/footer.routes";
import DefaultNavbar from "../navigation/DefaultNavbar";
import MKButton from "../../components/MKButton";
import View from "./View";
import CloseIcon from "@mui/icons-material/Close";
import {Dialog, useMediaQuery, useTheme} from "@mui/material";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import Stack from "@mui/material/Stack";

function ProductBaseLayout({ebayLink, etsyLink, title, images, summary, local = false, children}) {
    const theme = useTheme()
    const fullWidth = useMediaQuery(theme.breakpoints.down('md'));
    const flexDirection = fullWidth ? "column" : "row"
    const minHeight = fullWidth ? "300px" : "500px"
    const [show, setShow] = useState(false);
    const toggleModal = () => setShow(!show);

    function buyingOptions() {
        if (ebayLink && etsyLink) return <EbayAndEtsy ebayLink={ebayLink} etsyLink={etsyLink}/>
        else if (ebayLink) return <EbayOnly ebayLink={ebayLink}/>
        else if (etsyLink) return <EtsyOnly etsyLink={etsyLink}/>
        else return "Currently not available"
    }

    return (
        <>
            <Dialog open={show} onClose={toggleModal} fullWidth={fullWidth}>
                <MKBox display="flex" alignItems="center" justifyContent="space-between" m={2}>
                    <MKTypography variant="h5">Buying Options</MKTypography>
                    <CloseIcon fontSize="medium" sx={{cursor: "pointer"}} onClick={toggleModal}/>
                </MKBox>
                <MKBox display="flex" flexDirection={flexDirection} justifyContent="space-evenly" mx={4} mb={4}>
                    {buyingOptions()}
                </MKBox>
            </Dialog>
            <MKBox display="flex" flexDirection="column" bgColor="white" minHeight="100vh">
                <MKBox bgColor="white" shadow="sm" py={0.25}>
                    <DefaultNavbar
                        routes={routes}
                        transparent
                        relative
                    />
                </MKBox>
                <Container sx={{mt: 6}}>
                    <View>
                        <Stack>
                            <Grid container alignItems="center">
                                <Grid item xs={12} lg={6} sx={{ml: {xs: 0, lg: 3}, mt: {xs: 0, lg: 2}, mb: 2}}>
                                    <Carousel autoPlay={false}
                                              indicators={false}
                                              navButtonsAlwaysVisible={true}
                                              duration={300}
                                              sx={{
                                                  // overflow: "visible",
                                                  minHeight: {minHeight},
                                                  maxHeight: "500px",
                                              }}
                                    >
                                        {
                                            images.map((image) => {
                                                return <MKBox position="relative">
                                                    <MKBox
                                                        component="img"
                                                        src={image}
                                                        alt={""}
                                                        borderRadius="lg"
                                                        width="100%"
                                                        position="relative"
                                                        zIndex={1}
                                                        sx={{
                                                            objectFit: "contain"
                                                        }}
                                                    />
                                                </MKBox>
                                            })
                                        }
                                    </Carousel>
                                </Grid>
                                <Grid item xs={12} lg={5} sx={{ml: "auto"}}>
                                    <MKTypography variant="h4">{title}</MKTypography>
                                    <MKTypography variant="body2" color="text" mb={3}>
                                        {summary}
                                    </MKTypography>
                                    {
                                        !local && <Grid container spacing={1}>
                                            <Grid item xs={4}>
                                                <MKButton onClick={toggleModal} variant="gradient" color="info"
                                                          sx={{height: "100%"}}>
                                                    Buying Options
                                                </MKButton>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <MKBox sx={{mx: {xs: 0, lg: 3}, my: 3}}>
                                <MKTypography variant="h4">Item specifics</MKTypography>
                                {children}
                            </MKBox>
                        </Stack>
                    </View>
                </Container>
                <MKBox mt="auto">
                    <DefaultFooter content={footerRoutes}/>
                </MKBox>
            </MKBox>
        </>
    );
}

export default ProductBaseLayout;


function EbayAndEtsy(props) {
    const {ebayLink, etsyLink} = props;
    return <>
        <MKButton variant="gradient" color="white">
            <MKTypography
                component={Link}
                to={ebayLink}
                fontWeight="medium"
                textTransform="capitalize"
                variant="h2"
                color="info"
            >
                EBAY
            </MKTypography>
        </MKButton>
        <MKButton variant="gradient" color="white">
            <MKTypography
                component={Link}
                to={etsyLink}
                fontWeight="medium"
                textTransform="capitalize"
                variant="h2"
                color="warning"
            >
                ETSY
            </MKTypography>
        </MKButton>
    </>;
}

function EbayOnly(props) {
    const {ebayLink} = props;
    return <>
        <MKButton variant="gradient" color="white">
            <MKTypography
                component={Link}
                to={ebayLink}
                fontWeight="medium"
                textTransform="capitalize"
                variant="h2"
                color="info"
            >
                EBAY
            </MKTypography>
        </MKButton>
    </>;
}

function EtsyOnly(props) {
    const {etsyLink} = props;
    return <>
        <MKButton variant="gradient" color="white">
            <MKTypography
                component={Link}
                to={etsyLink}
                fontWeight="medium"
                textTransform="capitalize"
                variant="h2"
                color="warning"
            >
                ETSY
            </MKTypography>
        </MKButton>
    </>;
}