import {forwardRef} from "react";
import PropTypes from "prop-types";
import MKDateTimePickerRoot from "./MKDateTimePickerRoot";

const MKDateTimePicker = forwardRef(({ error, success, disabled, ...rest }, ref) => (
    <MKDateTimePickerRoot {...rest} ref={ref} ownerState={{ error, success, disabled }} />
));

// Setting default values for the props of MKInput
MKDateTimePicker.defaultProps = {
    error: false,
    success: false,
    disabled: false,
};

// Typechecking props for the MKInput
MKDateTimePicker.propTypes = {
    error: PropTypes.bool,
    success: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default MKDateTimePicker;
