import PropTypes from "prop-types";
import React, {useState} from 'react'

import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKButton from "../../components/MKButton";
import Chip from '@mui/material/Chip';
import {
    Autocomplete,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    useMediaQuery,
    useTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import DoneIcon from '@mui/icons-material/Done';
import MKInput from "../../components/MKInput";
import Grid from "@mui/material/Grid";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import MKDateTimePicker from "../../components/MKDateTimePicker";
import PetsIcon from '@mui/icons-material/Pets';
import {useMutation} from "@tanstack/react-query";
import {submitBoardingRequest} from "../../gateway/HomepageGateway";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from '@mui/icons-material/Check';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import ShowerIcon from "@mui/icons-material/Shower";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import Stack from "@mui/material/Stack";
import {green} from "@mui/material/colors";

function SteppingPricingCard({variant, color, bgColor, title, price, points, categories, animal, unit}) {
    const planName = title
    const [show, setShow] = useState(false);
    const [showAllStep, setShowAllStep] = useState(false);
    const [parentInformation, setParentInformation] = useState({});
    const [petInformation, setPetInformation] = useState({});
    const [showSecond, setShowSecond] = useState(false);
    const [secondPetInformation, setSecondPetInformation] = useState({});
    const [startDateTime, setStartDateTime] = useState();
    const [endDateTime, setEndDateTime] = useState();
    const [notes, setNotes] = useState();
    const [extraServiceArray, setExtraServiceArray] = useState([
        {key: 0, label: 'Shampoo', selected: false, price: '$30', icon: <ShowerIcon/>},
        {key: 1, label: 'Cutting nails', selected: false, price: '$10', icon: <ContentCutIcon/>},
        {key: 2, label: 'Pick up', selected: false, icon: <DepartureBoardIcon/>},
        {key: 3, label: 'Drop off', selected: false, icon: <DriveEtaIcon/>}
    ]);
    const extraServices = extraServiceArray.filter((chip) => chip.selected).map((service) => service.label)
    const [pickupNotes, setPickupNotes] = useState();
    const [dropoffNotes, setDropoffNotes] = useState();
    const mutation = useMutation({mutationFn: submitBoardingRequest})
    const toggleModal = () => setShow(!show);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const fullWidth = useMediaQuery(theme.breakpoints.down('md'));

    const [activeStep, setActiveStep] = useState(0);
    const pickupSelected = extraServiceArray[2]?.selected
    const dropoffSelected = extraServiceArray[3]?.selected

    const handleClick = (chipSelected) => () => {
        const newChipData = [...extraServiceArray]
        newChipData.forEach((chip) => {
            if (chip.key === chipSelected.key) chip.selected = !chip.selected
        })
        setExtraServiceArray(newChipData)
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleExpirationDateChange = (event) => {
        let textTemp = event.target.value;
        if (textTemp.length === 2) {
            textTemp += '/';
        }
        setPetInformation(petInformation => ({
            ...petInformation,
            ...{"rabiesExpDate": textTemp}
        }))
    }

    const onCloseSuccessDialog = () => {
        mutation.reset()
        setShow(!show)
        setShowSecond(false)
        setParentInformation({})
        setPetInformation({})
        setSecondPetInformation({})
        setStartDateTime("")
        setEndDateTime("")
        setNotes("")
        setActiveStep(0)
        setExtraServiceArray([])
        setPickupNotes("")
        setDropoffNotes("")
    };

    function shouldDisableContinue(parentInformation, petInformation) {
        return !parentInformation.firstName || !parentInformation.lastName ||
            !parentInformation.email || !parentInformation.phoneNumber ||
            !petInformation.petName || !petInformation.age || !petInformation.weight ||
            !petInformation.animalType || !petInformation.isAltered ||
            !petInformation.gender || !petInformation.rabiesExpDate;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog open={mutation.isSuccess} onClose={onCloseSuccessDialog}>
                <DialogTitle>
                    <MKBox display="flex" alignItems="center" justifyContent="start" p={1}>
                        <CheckCircleIcon fontSize='large' color='success' sx={{mr: 1}}/>
                        <MKTypography variant="h4">Success</MKTypography>
                    </MKBox>
                </DialogTitle>
                <MKBox
                    position="relative"
                    // width="400px"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    sx={{p: 1, ml: 2, mr: 2}}
                >
                    <MKTypography variant="body1" opacity={0.8} mt={1} mb={1}>
                        Your boarding request has been submitted successfully. We will response to you within 24
                        hours. Thank you for your business.
                    </MKTypography>
                </MKBox>
                <DialogActions>
                    <MKButton color="info" onClick={onCloseSuccessDialog}>
                        OK
                    </MKButton>
                </DialogActions>
            </Dialog>
            <Dialog open={mutation.error} onClose={() => {
                mutation.reset()
            }}>
                <DialogTitle>
                    <MKBox display="flex" alignItems="center" justifyContent="start" p={1}>
                        <CancelIcon fontSize='large' color='error' sx={{mr: 1}}/>
                        <MKTypography variant="h4">Error</MKTypography>
                    </MKBox>
                </DialogTitle>
                <MKBox
                    position="relative"
                    // width="400px"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    sx={{p: 1, ml: 2, mr: 2}}
                >
                    <MKTypography variant="body1" opacity={0.8} mt={1} mb={1}>
                        An unexpected error occurred. Please try again later.
                    </MKTypography>
                </MKBox>
                <DialogActions>
                    <MKButton color="info" onClick={() => {
                        mutation.reset()
                    }}>
                        OK
                    </MKButton>
                </DialogActions>
            </Dialog>
            <Modal open={show} onClose={toggleModal} sx={{display: "grid", placeItems: "center", overflow: "auto"}}
                   fullScreen={fullScreen} fullWidth>
                <MKBox
                    position="relative"
                    width={fullWidth ? undefined : "800px"}
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                >
                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                        <MKBox display="flex" alignItems="center" justifyContent="start" p={1}>
                            <PetsIcon fontSize='medium' sx={{mr: 1}}/>
                            <MKTypography variant="h4">Submit boarding request</MKTypography>
                        </MKBox>
                        <CloseIcon fontSize="medium" sx={{cursor: "pointer"}} onClick={toggleModal}/>
                    </MKBox>
                    <Divider sx={{my: 0}}/>
                    <MKBox display="flex" alignItems="center" justifyContent="space-between" px={2} sx={{mb: 5}}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            <Step active={activeStep === 0 || showAllStep}>
                                <StepLabel>
                                    <MKTypography variant="h5">Basic Information</MKTypography>
                                </StepLabel>
                                <StepContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <MKTypography variant="h6">Parent</MKTypography>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <MKInput variant="standard" label="First Name" fullWidth required
                                                     value={parentInformation.firstName}
                                                     onChange={(event) => {
                                                         setParentInformation(parentInformation => ({
                                                             ...parentInformation,
                                                             ...{"firstName": event.target.value}
                                                         }))
                                                     }}/>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <MKInput variant="standard" label="Last Name" fullWidth required
                                                     value={parentInformation.lastName}
                                                     onChange={(event) => {
                                                         setParentInformation(parentInformation => ({
                                                             ...parentInformation,
                                                             ...{"lastName": event.target.value}
                                                         }))
                                                     }}/>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <MKInput variant="standard" type="email" label="Email Address" fullWidth
                                                     required
                                                     value={parentInformation.email}
                                                     onChange={(event) => {
                                                         setParentInformation(parentInformation => ({
                                                             ...parentInformation,
                                                             ...{"email": event.target.value}
                                                         }))
                                                     }}/>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <MKInput variant="standard" type="tel" label="Phone number" fullWidth
                                                     required
                                                     value={parentInformation.phoneNumber}
                                                     onChange={(event) => {
                                                         setParentInformation(parentInformation => ({
                                                             ...parentInformation,
                                                             ...{"phoneNumber": event.target.value}
                                                         }))
                                                     }}/>
                                        </Grid>
                                        <>
                                            <Grid item xs={12}>
                                                <MKTypography variant="h6">Pet</MKTypography>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <MKInput variant="standard" label="Pet Name" fullWidth required
                                                         value={petInformation.petName}
                                                         onChange={(event) => {
                                                             setPetInformation(petInformation => ({
                                                                 ...petInformation,
                                                                 ...{"petName": event.target.value}
                                                             }))
                                                         }}/>
                                            </Grid>
                                            <Grid item xs={6} md={1}>
                                                <MKInput variant="standard" type="number" label="Age" fullWidth required
                                                         value={petInformation.age}
                                                         onChange={(event) => {
                                                             setPetInformation(petInformation => ({
                                                                 ...petInformation,
                                                                 ...{"age": event.target.value}
                                                             }))
                                                         }}
                                                         inputProps={{
                                                             max: 99,
                                                             min: 0,
                                                             step: 1,
                                                         }}/>
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <FormControl required variant="standard" sx={{width: '100%'}}>
                                                    <InputLabel>Sex</InputLabel>
                                                    <Select
                                                        value={petInformation.gender}
                                                        onChange={(event) => {
                                                            setPetInformation(petInformation => ({
                                                                ...petInformation,
                                                                ...{"gender": event.target.value}
                                                            }))
                                                        }}
                                                    >
                                                        <MenuItem value={"Male"}>Male</MenuItem>
                                                        <MenuItem value={"Female"}>Female</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <FormControl required variant="standard" sx={{width: '100%'}}>
                                                    <InputLabel>Altered</InputLabel>
                                                    <Select
                                                        value={petInformation.isAltered}
                                                        onChange={(event) => {
                                                            setPetInformation(petInformation => ({
                                                                ...petInformation,
                                                                ...{"isAltered": event.target.value}
                                                            }))
                                                        }}
                                                    >
                                                        <MenuItem value={"true"}>Yes</MenuItem>
                                                        <MenuItem value={"false"}>No</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <MKInput variant="standard" label={`Weight (${unit})`} type="number"
                                                         value={petInformation.weight} required
                                                         onChange={(event) => {
                                                             setPetInformation(petInformation => ({
                                                                 ...petInformation,
                                                                 ...{"weight": event.target.value}
                                                             }))
                                                         }}
                                                         inputProps={{
                                                             min: 0,
                                                             step: 0.5,
                                                         }} fullWidth/>
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <MKInput variant="standard" label={"Rabies Exp Date"}
                                                         value={petInformation.rabiesExpDate} required
                                                         placeholder="MM/YY"
                                                         onChange={handleExpirationDateChange}
                                                         inputProps={{
                                                             min: 0,
                                                             step: 0.5,
                                                             maxLength: 5
                                                         }} fullWidth/>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Autocomplete
                                                    disablePortal
                                                    options={categories}
                                                    value={petInformation.animalType}
                                                    onChange={(event, newValue) => {
                                                        setPetInformation(petInformation => ({
                                                            ...petInformation,
                                                            ...{"animalType": newValue}
                                                        }))
                                                    }}
                                                    renderInput={(params) =>
                                                        <MKInput required variant="standard" {...params}
                                                                 label={animal + " Breed"}/>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <MKInput variant="standard" label="If other" fullWidth
                                                         value={petInformation.otherAnimalType}
                                                         onChange={(event) => {
                                                             setPetInformation(petInformation => ({
                                                                 ...petInformation,
                                                                 ...{"otherAnimalType": event.target.value}
                                                             }))
                                                         }}/>

                                            </Grid>
                                        </>
                                        {showSecond && <>
                                            <Grid item xs={12}>
                                                <MKTypography variant="h6">Second Pet</MKTypography>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <MKInput variant="standard" label="Pet Name" fullWidth required
                                                         value={secondPetInformation.petName}
                                                         onChange={(event) => {
                                                             setSecondPetInformation(secondPetInformation => ({
                                                                 ...secondPetInformation,
                                                                 ...{"petName": event.target.value}
                                                             }))
                                                         }}/>
                                            </Grid>
                                            <Grid item xs={6} md={1}>
                                                <MKInput variant="standard" type="number" label="Age" fullWidth
                                                         required
                                                         value={secondPetInformation.age}
                                                         onChange={(event) => {
                                                             setSecondPetInformation(secondPetInformation => ({
                                                                 ...secondPetInformation,
                                                                 ...{"age": event.target.value}
                                                             }))
                                                         }}
                                                         inputProps={{
                                                             max: 99,
                                                             min: 0,
                                                             step: 1,
                                                         }}/>
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <FormControl required variant="standard" sx={{width: '100%'}}>
                                                    <InputLabel>Sex</InputLabel>
                                                    <Select
                                                        value={secondPetInformation.gender}
                                                        onChange={(event) => {
                                                            setSecondPetInformation(secondPetInformation => ({
                                                                ...secondPetInformation,
                                                                ...{"gender": event.target.value}
                                                            }))
                                                        }}
                                                    >
                                                        <MenuItem value={"Male"}>Male</MenuItem>
                                                        <MenuItem value={"Female"}>Female</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <FormControl required variant="standard" sx={{width: '100%'}}>
                                                    <InputLabel>Altered</InputLabel>
                                                    <Select
                                                        value={secondPetInformation.isAltered}
                                                        onChange={(event) => {
                                                            setSecondPetInformation(secondPetInformation => ({
                                                                ...secondPetInformation,
                                                                ...{"isAltered": event.target.value}
                                                            }))
                                                        }}
                                                    >
                                                        <MenuItem value={"true"}>Yes</MenuItem>
                                                        <MenuItem value={"false"}>No</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <MKInput variant="standard" label={`Weight (${unit})`} type="number"
                                                         value={secondPetInformation.weight} required
                                                         onChange={(event) => {
                                                             setSecondPetInformation(secondPetInformation => ({
                                                                 ...secondPetInformation,
                                                                 ...{"weight": event.target.value}
                                                             }))
                                                         }}
                                                         inputProps={{
                                                             min: 0,
                                                             step: 0.5,
                                                         }} fullWidth/>
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <MKInput variant="standard" label={"Rabies Exp Date"}
                                                         value={secondPetInformation.rabiesExpDate} required
                                                         placeholder="MM/YY"
                                                         onChange={(event) => {
                                                             let textTemp = event.target.value;
                                                             if (textTemp.length === 2) {
                                                                 textTemp += '/';
                                                             }
                                                             setSecondPetInformation(secondPetInformation => ({
                                                                 ...secondPetInformation,
                                                                 ...{"rabiesExpDate": textTemp}
                                                             }))
                                                         }}
                                                         inputProps={{
                                                             min: 0,
                                                             step: 0.5,
                                                             maxLength: 5
                                                         }} fullWidth/>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Autocomplete
                                                    disablePortal
                                                    options={categories}
                                                    value={secondPetInformation.animalType}
                                                    onChange={(event, newValue) => {
                                                        setSecondPetInformation(secondPetInformation => ({
                                                            ...secondPetInformation,
                                                            ...{"animalType": newValue}
                                                        }))
                                                    }}
                                                    renderInput={(params) =>
                                                        <MKInput required variant="standard" {...params}
                                                                 label={animal + " Breed"}/>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <MKInput variant="standard" label="If other" fullWidth
                                                         value={secondPetInformation.otherAnimalType}
                                                         onChange={(event) => {
                                                             setSecondPetInformation(secondPetInformation => ({
                                                                 ...secondPetInformation,
                                                                 ...{"otherAnimalType": event.target.value}
                                                             }))
                                                         }}/>

                                            </Grid>
                                        </>}
                                    </Grid>
                                    {!showAllStep && <MKBox sx={{mt: 4}}>
                                        <MKButton
                                            variant="gradient" color="info"
                                            onClick={handleNext}
                                            sx={{mr: 2}}
                                            disabled={showSecond ? shouldDisableContinue(parentInformation, petInformation) || shouldDisableContinue(parentInformation, secondPetInformation) :
                                                shouldDisableContinue(parentInformation, petInformation)}
                                        >
                                            Continue
                                        </MKButton>
                                        <MKButton
                                            variant="gradient" color="secondary"
                                            onClick={() => {
                                                setShowSecond(!showSecond)
                                            }}
                                        >
                                            {showSecond ? "Remove second pet" : "Add second pet"}
                                        </MKButton>
                                    </MKBox>}
                                </StepContent>
                            </Step>
                            <Step active={activeStep === 1 || showAllStep}>
                                <StepLabel>
                                    <MKTypography variant="h5">Boarding</MKTypography>
                                </StepLabel>
                                <StepContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <MKTypography variant="h6">Time</MKTypography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <MKDateTimePicker
                                                label="Start Time"
                                                value={startDateTime}
                                                disablePast={true}
                                                onChange={(newValue) => {
                                                    setStartDateTime(newValue);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <MKDateTimePicker
                                                label="End Time"
                                                disablePast={true}
                                                value={endDateTime}
                                                onChange={(newValue) => {
                                                    setEndDateTime(newValue);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MKTypography variant="h6">Notes</MKTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MKInput variant="standard" multiline fullWidth rows={3}
                                                     value={notes}
                                                     onChange={(event) => {
                                                         setNotes(event.target.value);
                                                     }}/>
                                        </Grid>
                                    </Grid>
                                    {!showAllStep && <MKBox sx={{mt: 2}}>
                                        <MKButton
                                            variant="gradient" color="info"
                                            onClick={handleNext}
                                            sx={{mr: 2}}
                                            disabled={!startDateTime || !endDateTime}
                                        >
                                            Continue
                                        </MKButton>
                                        <MKButton
                                            variant="gradient" color="light"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        >
                                            Back
                                        </MKButton>
                                    </MKBox>}
                                </StepContent>
                            </Step>
                            <Step active={activeStep === 2 || showAllStep}>
                                <StepLabel>
                                    <MKTypography variant="h5">Extra Service</MKTypography>
                                </StepLabel>
                                <StepContent>
                                    <>
                                        <Stack alignItems="start" spacing={2}>
                                            <Stack spacing={1} alignItems={{xs: 'start', sm: "center"}}
                                                   direction={{xs: 'column', sm: 'row'}}
                                                   sx={{mt: 1}}>
                                                {extraServiceArray?.map((data) => {
                                                    return (
                                                        <Chip
                                                            size={'large'}
                                                            color={data?.selected ? "success" : "default"}
                                                            label={data.price ? data.label + " - " + data.price : data.label}
                                                            variant={data?.selected ? "filled" : "outlined"}
                                                            deleteIcon={data?.selected ? <DoneIcon/> : undefined}
                                                            onClick={handleClick(data)}
                                                            icon={data?.selected ? <DoneIcon/> : data.icon}
                                                        />
                                                    );
                                                })}
                                            </Stack>
                                            {
                                                (pickupSelected || dropoffSelected) && <>
                                                    <MKTypography variant="caption" color="text" mb={1}>
                                                        * Pick up and drop off service are only available within the 15
                                                        miles range of GMU. The charge
                                                        will be the same as Uber Pet
                                                    </MKTypography>
                                                    <MKTypography variant="caption" color="text" mb={1} mt={1}>
                                                        * The charge will be the same as Uber Pet.
                                                    </MKTypography>
                                                </>
                                            }
                                            {
                                                pickupSelected && <MKInput variant="standard"
                                                                           placeholder="Please leave pick up address and time in here..."
                                                                           multiline fullWidth rows={3}
                                                                           value={pickupNotes}
                                                                           onChange={(event) => {
                                                                               setPickupNotes(event.target.value);
                                                                           }}/>
                                            }
                                            {
                                                dropoffSelected && <MKInput variant="standard"
                                                                            placeholder="Please leave drop off address and time in here..."
                                                                            multiline fullWidth rows={3}
                                                                            value={dropoffNotes}
                                                                            onChange={(event) => {
                                                                                setDropoffNotes(event.target.value);
                                                                            }}/>
                                            }
                                        </Stack>
                                    </>
                                    <MKBox sx={{mt: 4}}>
                                        <MKButton variant="gradient" color="info"
                                                  sx={{mr: 2}}
                                                  disabled={mutation.isLoading}
                                                  onClick={() => {
                                                      let petsInformation = [petInformation]
                                                      if (showSecond) {
                                                          petsInformation = [...petsInformation, secondPetInformation]
                                                      }
                                                      mutation.mutate({
                                                          petsInformation,
                                                          parentInformation,
                                                          startDateTime,
                                                          endDateTime,
                                                          notes,
                                                          animal,
                                                          unit,
                                                          extraServices,
                                                          pickupNotes,
                                                          dropoffNotes,
                                                          planName
                                                      })
                                                  }}>
                                            Submit
                                        </MKButton>
                                        {mutation.isLoading && <CircularProgress thickness={4.5} sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}/>}
                                        <MKButton
                                            variant="gradient" color="secondary"
                                            onClick={() => {
                                                setShowAllStep(!showAllStep)
                                            }}
                                            disabled={mutation.isLoading}
                                            sx={{mr: 2}}
                                        >
                                            {showAllStep ? "Close Review" : "Review"}
                                        </MKButton>
                                        <MKButton
                                            variant="gradient" color="light"
                                            disabled={activeStep === 0 || mutation.isLoading}
                                            onClick={handleBack}
                                        >
                                            Back
                                        </MKButton>
                                    </MKBox>
                                </StepContent>
                            </Step>
                        </Stepper>
                    </MKBox>
                </MKBox>
            </Modal>
            <MKBox
                display={{xs: "block", md: "flex"}}
                variant={variant}
                bgColor={bgColor}
                borderRadius="xl"
                pt={3.5}
                pb={3}
                px={1}
            >
                <MKBox pt={{xs: 3, md: 0}} pl={{xs: 0, md: 2}} lineHeight={1}>
                    <MKBox display="flex" alignItems="center" justifyContent="start">
                        <MKTypography
                            display="block"
                            variant="5"
                            color={variant === "contained" || color === "light" ? "dark" : "white"}
                            fontWeight="bold"
                            mb={1}
                        >
                            {title}
                        </MKTypography>
                    </MKBox>
                    <MKTypography
                        display="h3"
                        variant="h2"
                        color={variant === "contained" || color === "light" ? "dark" : "white"}
                        fontWeight="bold"
                        mb={1}
                    >
                        {price}
                    </MKTypography>
                    <MKBox
                        pt={1}
                        pb={3}
                    >
                        {points.map((point) => {
                            return <MKBox display="flex" alignItems="center" justifyContent="start" p={0.5}>
                                <CheckIcon color='success' sx={{mr: 1}}/>
                                <MKTypography
                                    opacity={1}
                                    variant="body2"
                                >
                                    {point}
                                </MKTypography>
                            </MKBox>
                        })}
                    </MKBox>
                    <MKButton color="dark" onClick={toggleModal}>
                        Schedule a Meet&Greet
                    </MKButton>
                </MKBox>
            </MKBox>
        </LocalizationProvider>
    );
}

// Setting default props for the FilledInfoCard
SteppingPricingCard.defaultProps = {
    variant: "contained",
    color: "info",
    action: false,
};

// Typechecking props for the FilledInfoCard
SteppingPricingCard.propTypes = {
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    title: PropTypes.string.isRequired,
    action: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            type: PropTypes.oneOf(["external", "internal"]).isRequired,
            route: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ]),
};

export default SteppingPricingCard;
