import Container from "@mui/material/Container";
import MKBox from "../../../components/MKBox";
import React, {useEffect, useState} from 'react'
import routes from "../../../routes";
import DefaultFooter from "../../footer/Footer";
import footerRoutes from "../../footer/footer.routes";
import DefaultNavbar from "../../navigation/DefaultNavbar";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MKTypography from "../../../components/MKTypography";
import Grid from "@mui/material/Grid";
import CollapsibleRow from "../CollapsibleRow";
import BoardingFAQ from "../BoardingFAQ";
import DogBoardingRules from "./DogBoardingRules";
import breakpoints from "../../../assets/theme/base/breakpoints";
import {styled} from "@mui/material/styles";
import ImageWall from "../ImageWall";
import {dogImageData} from "./data/DogImageData";
import ExtraService from "./ExtraService";
import {dogBoardingFaqData} from "./data/DogBoardingFAQData";
import {dogPriceData} from "./data/DogPriceData";
import {Toolbar} from "@mui/material";

function DogBoarding() {
    const [mobileView, setMobileView] = useState(false);
    const barTypographyVariant = mobileView ? "body2" : "body1"

    useEffect(() => {
        function displayMobileNavbar() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
            } else {
                setMobileView(false);
            }
        }
        window.addEventListener("resize", displayMobileNavbar);
        displayMobileNavbar();
        return () => window.removeEventListener("resize", displayMobileNavbar);
    }, []);

    return (
        <>
            <MKBox display="flex" flexDirection="column" bgColor="white" minHeight="100vh">
                <MKBox bgColor="white" shadow="sm" py={0.25}>
                    <DefaultNavbar
                        routes={routes}
                        transparent
                        relative
                    />
                </MKBox>
                <MKBox>

                </MKBox>
                {/*<Toolbar variant="dense" sx={{*/}
                {/*    backgroundColor: "#ffa700",*/}
                {/*    alignItems: "center",*/}
                {/*    justifyContent: "center"*/}
                {/*}}>*/}
                {/*    /!*<MKTypography color="white" fontWeight="bold" verticalAlign="middle" variant={barTypographyVariant}>*!/*/}
                {/*    /!*    Dog daycare and boarding are not available from August 15th to September 11th*!/*/}
                {/*    /!*</MKTypography>*!/*/}
                {/*</Toolbar>*/}
                <Container sx={{mt: 6}}>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={6}
                        flexDirection="column"
                        alignItems="center"
                        sx={{textAlign: "center", my: 6, mx: "auto", px: 0.75}}
                    >
                        <MKTypography variant="h2" fontWeight="bold">
                            Dog Boarding
                        </MKTypography>
                        <MKTypography variant="body1" color="text">

                        </MKTypography>
                    </Grid>
                    <MKBox>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell/>
                                        <StyledTableCell>Size</StyledTableCell>
                                        {!mobileView && <StyledTableCell align="right">Standard</StyledTableCell>}
                                        {!mobileView && <StyledTableCell align="right">Plus</StyledTableCell>}
                                        {!mobileView && <StyledTableCell align="right">Premium</StyledTableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dogPriceData.map((priceData, index) => <CollapsibleRow
                                        mobileView={mobileView}
                                        priceData={priceData}
                                        openByDefault={index === 0}
                                    />)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <ImageWall data={dogImageData} mobileView={mobileView}/>
                        <ExtraService/>
                        <DogBoardingRules/>
                        <BoardingFAQ faqs={dogBoardingFaqData}/>
                        {/*<Testimonial reviewData={dogReviewData}/>*/}
                    </MKBox>
                </Container>
                <MKBox mt="auto">
                    <DefaultFooter content={footerRoutes}/>
                </MKBox>
            </MKBox>
        </>
    );
}

export const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#44b04e",
        color: theme.palette.common.white,
        fontSize: 20,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 18,
    },
}));

export const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default DogBoarding;
