import HomeHeader from "./header/HomeHeader";
import Features from "./features/Features";
import DefaultFooter from "../footer/Footer";
import footerRoutes from "../footer/footer.routes";
import React from 'react'

function Home() {
    return (
        <>
            <HomeHeader/>
            <Features/>
            <DefaultFooter content={footerRoutes}/>
        </>
    );
}

export default Home;
