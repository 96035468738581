import MKTypography from "components/MKTypography";
import React from 'react'
import cockaLogo from "assets/images/cocka1.png";

const date = new Date().getFullYear();
export const ebayShop = "https://www.ebay.com/usr/jiach_5702"
export const etsyShop = "https://www.etsy.com/shop/SEEquailshop"

export default {
    brand: {
        name: "COCKA",
        image: cockaLogo
    },
    socials: [],
    menus: [
        {
            name: "Operating Hours",
            items: [
                {name: "9 AM - 9 PM"},
                {name: "Monday - Sunday"},
            ],
        },
        {
            name: "our shop",
            items: [
                {name: "about us", href: "/about"},
                {name: "ebay", href: ebayShop},
                {name: "etsy", href: etsyShop},
            ],
        },
        // {
        //     name: "resources",
        //     items: [
        //         {name: "How To", href: "/how"},
        //         {name: "Quail Wiki", href: "https://en.wikipedia.org/wiki/Quail"},
        //         {name: "Button Quail Wiki", href: "https://en.wikipedia.org/wiki/Buttonquail"},
        //     ],
        // },
        {
            name: "help & support",
            items: [
                {name: "contact us", href: "/contact"}
            ],
        },
        {
            name: "credit",
            items: [
                {name: "flaticon", href: "https://www.flaticon.com/"},
                {name: "Creative Tim", href: "https://www.creative-tim.com"}
            ],
        }
    ],
    copyright: (
        <MKTypography variant="button" fontWeight="regular">
            All rights reserved. Copyright &copy; {date} by{" "}
            <MKTypography
                component="a"
                rel="noreferrer"
                variant="button"
                fontWeight="regular"
            >
                COCKA LLC
            </MKTypography>
        </MKTypography>
    ),
};
