import Grid from "@mui/material/Grid";
import MKTypography from "../../components/MKTypography";
import ImageCard from "../../components/ImageCard";
import Carousel from "react-material-ui-carousel";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import MKBox from "../../components/MKBox";
import Card from "@mui/material/Card";
import React from 'react'

export default function ImageWall({data, mobileView = false}) {
    function buildImageMap(data) {
        const descriptionByImage = new Map()
        data.forEach(({description, images}) => {
            images.forEach((image) => {
                descriptionByImage.set(image, description)
            })
        })
        return descriptionByImage;
    }

    const descriptionByImage = buildImageMap(data);

    return <>
        <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            alignItems="center"
            sx={{textAlign: "center", my: 6, mx: "auto", px: 0.75}}
        >
            <MKTypography variant="h2" fontWeight="bold">
                Dogs in Cocka
            </MKTypography>
        </Grid>
        {mobileView ? <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card sx={{
                        background: "transparent",
                        boxShadow: "none",
                        overflow: "visible",
                        height: "350px",
                    }}>
                        <Carousel
                            autoPlay={false}
                            indicators={false}
                            navButtonsAlwaysVisible={true}
                            NextIcon={<NavigateNextIcon fontSize='small'/>}
                            PrevIcon={<NavigateBeforeIcon fontSize='small'/>}
                            sx={{
                                overflow: "visible",
                                height: "300px",
                            }}
                        >
                            {
                                Array.from(descriptionByImage.entries()).map(([key, value]) => {
                                    return <MKBox position="relative" borderRadius="lg">
                                        <MKBox
                                            component="img"
                                            src={key}
                                            alt={value}
                                            borderRadius="lg"
                                            width="100%"
                                            position="relative"
                                            zIndex={1}
                                            sx={{
                                                height: "300px",
                                                objectFit: "cover"
                                            }}
                                        />
                                        <MKBox p={1} mt={-1} textAlign="center">
                                            <MKBox mt={1} mb={1}>
                                                <MKTypography variant="body2" component="p" color="text">
                                                    {value}
                                                </MKTypography>
                                            </MKBox>
                                        </MKBox>
                                    </MKBox>

                                })
                            }
                        </Carousel>
                    </Card>
                </Grid>
            </Grid> :
            <Grid container spacing={3}>
                {data.map(({description, images}) => {
                    return <Grid item xs={12} md={3}>
                        <ImageCard images={images} description={description}/>
                    </Grid>
                })}
            </Grid>
        }
    </>
}