import babyChick from "../../../assets/images/babychick.jpg";
import smallAnimal from "../../../assets/images/smallanimal.jpg";
import dogBoarding from "../../../assets/images/dogboarding.jpg";

export const headerData = [
    {
        image: dogBoarding,
        title: "Dog Boarding",
        description: "We love all dogs in the world.",
        route: "/boarding/dog"
    },
    {
        image: smallAnimal,
        title: "Small Animal Boarding",
        description: "Bunnies, Guinea Pigs, parrots and more.",
        route: "/boarding/small-animal"
    },
    {
        image: babyChick,

        title: "Baby Quails",
        description: "It's time now to add the newborns to your family!"
    },

]