import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React from 'react'

function ProductCard({ image, name, count, local, description, ...rest }) {
    const imageTemplate = (
        <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            minHeight="20rem"
            minWidth="20rem"
            sx={{
                overflow: "hidden",
                transform: "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)",
                transformOrigin: "50% 0",
                backfaceVisibility: "hidden",
                willChange: "transform, box-shadow",
                transition: "transform 200ms ease-out",

                "&:hover": {
                    transform: "perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px)",
                },
            }}
            {...rest}
        >
            {local && (
                <MKBox position="absolute" top={0} right={0} zIndex={2} p={1}>
                    <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                        <g id="home-black" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <circle id="Oval" fill="#1F2937" cx="12" cy="12" r="12" />
                            <g
                                id="home"
                                transform="translate(4.000000, 3.000000)"
                                fill="#FFFFFF"
                                fillRule="nonzero"
                            >
                                <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z"/>
                            </g>
                        </g>
                    </svg>
                </MKBox>
            )}
            <MKBox
                component="img"
                src={image}
                alt={name}
                width="100%"
                my="auto"
                // opacity={local ? 0.6 : 1}
            />
        </MKBox>
    );

    return (
        <MKBox position="relative">
            {local ? (
                <Tooltip title="Local Pickup Only" placement="top">
                    {imageTemplate}
                </Tooltip>
            ) : (
                imageTemplate
            )}
            {name || count > 0 ? (
                <MKBox mt={1} ml={1} lineHeight={1}>
                    {name && (
                        <MKTypography variant="h6" fontWeight="bold">
                            {name}
                        </MKTypography>
                    )}
                    {count > 0 && (
                        <MKTypography variant="button" fontWeight="regular" color="secondary">
                            {description}
                        </MKTypography>
                    )}
                </MKBox>
            ) : null}
        </MKBox>
    );
}

// Setting default props for the ExampleCard
ProductCard.defaultProps = {
    name: "",
    count: 0,
    pro: false,
};

// Typechecking props for the ExampleCard
ProductCard.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string,
    count: PropTypes.number,
    pro: PropTypes.bool,
};

export default ProductCard;
