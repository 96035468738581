import Home from "./pages/home/Home";
import Contact from "./pages/contact/Contact";
import ProductBaseLayout from "./pages/products/ProductBaseLayout";

/**
 All of the routes for the Material Kit 2 React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Navbar.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `name` key is used for the name of the route on the Navbar.
 2. The `icon` key is used for the icon of the route on the Navbar.
 3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 4. The `route` key is used to store the route location which is used for the react router.
 5. The `href` key is used to store the external links location.
 6. The `component` key is used to store the component of its route.
 7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
 8. The `description` key is used to define the description of
 a route under its name.
 9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
 you can set the columns amount based on this key.
 10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
 */
import buttoneggs1 from "assets/images/products/buttoneggs1.jpg";
import coturnixQuailHatchingEggMin from "assets/images/products/product-2-min.jpg";
import MKTypography from "./components/MKTypography";
import SmallAnimal from "./pages/boarding/small/SmallAnimal";
import DogBoarding from "./pages/boarding/dog/DogBoarding";
import React from 'react';
import Shop from "./pages/shop/Shop";

const routes = [
    {
        name: "home",
        route: "/",
        show: false,
        component: <Home/>
    },
    // {
    //     name: "Quail",
    //     route: "/",
    //     component: <Home/>
    // },
    // {
    //     name: "Dog",
    //     route: "/",
    //     component: <Home/>
    // },
    // {
    //     name: "Custom Gift",
    //     route: "/",
    //     component: <Home/>
    // },
    {
        name: "Small Animal Boarding",
        route: "/boarding/small-animal",
        component: <SmallAnimal/>,
        show: true
    },
    {
        name: "Dog Boarding",
        route: "/boarding/dog",
        component: <DogBoarding/>,
        show: true
    },
    // {
    //     name: "Resources",
    //     collapse: [
    //         {
    //             name: "About Us",
    //             route: "/about",
    //             component: <About/>,
    //         },
    //         {
    //             name: "How to Take Care",
    //             route: "/How",
    //             component: <How/>,
    //         },
    //         {
    //             name: "FAQ",
    //             route: "/faq",
    //             component: <FAQ/>,
    //         },
    //     ]
    // },
    {
        name: "cocka shop",
        route: "/shop",
        component: <Shop/>,
        show: true
    },
    {
        name: "contact us",
        route: "/contact",
        component: <Contact/>,
        show: true
    },
    {
        name: "button-quail-hatching-egg",
        route: "/product/button-quail-hatching-egg",
        component: <ProductBaseLayout
            ebayLink={"https://www.ebay.com/itm/166019852747?hash=item26a78de9cb:g:65kAAOSwbmtkQfk~"}
            etsyLink={"https://www.etsy.com/listing/1455473705/button-quail-eggs-free-shipping?click_key=5226f2b1a3ca5fa9e9a6a256c4de40943c3fdb00%3A1455473705&click_sum=1aee1889&ref=shop_home_active_1&frs=1&crt=1&sts=1"}
            title={"Button Quail Hatching Egg"}
            images={[buttoneggs1]}
            summary={"put summary here"}
        >
            <MKTypography variant="body2" color="text" mb={3}>
                Item spec here
            </MKTypography>
            <MKTypography variant="body3" color="text" mb={3}>
                body3 type
            </MKTypography>
        </ProductBaseLayout>,
        show: false
    },
    {
        name: "coturnix-quail-hatching-egg",
        route: "/product/coturnix-quail-hatching-egg",
        component: <ProductBaseLayout
            ebayLink={"https://www.ebay.com/itm/166019852747?hash=item26a78de9cb:g:65kAAOSwbmtkQfk~"}
            etsyLink={"https://www.etsy.com/listing/1455473705/button-quail-eggs-free-shipping?click_key=5226f2b1a3ca5fa9e9a6a256c4de40943c3fdb00%3A1455473705&click_sum=1aee1889&ref=shop_home_active_1&frs=1&crt=1&sts=1"}
            title={"Coturnix Quail Hatching Egg"}
            images={[buttoneggs1, coturnixQuailHatchingEggMin]}
            summary={"put summary here"}
        >
            <MKTypography variant="body2" color="text" mb={3}>
                Item spec here
            </MKTypography>
            <MKTypography variant="body3" color="text" mb={3}>
                body3 type
            </MKTypography>
        </ProductBaseLayout>,
        show: false
    },
    {
        name: "button-quail-baby-chick",
        route: "/product/button-quail-baby-chick",
        component: <ProductBaseLayout title={"Button Quail Baby Chick"}
                                      images={[buttoneggs1]}
                                      summary={"put summary here"}
                                      local={true}
        >
            <MKTypography variant="body2" color="text" mb={3}>
                Item spec here
            </MKTypography>
            <MKTypography variant="body3" color="text" mb={3}>
                body3 type
            </MKTypography>
        </ProductBaseLayout>,
        show: false
    },
    {
        name: "coturnix-quail-baby-chick",
        route: "/product/coturnix-quail-baby-chick",
        component: <ProductBaseLayout title={"Coturnix Quail Hatching Egg"}
                                      images={[buttoneggs1, coturnixQuailHatchingEggMin]}
                                      summary={"put summary here"}
                                      local={true}
        >
            <MKTypography variant="body2" color="text" mb={3}>
                Item spec here
            </MKTypography>
            <MKTypography variant="body3" color="text" mb={3}>
                body3 type
            </MKTypography>
        </ProductBaseLayout>,
        show: false
    },
    {
        name: "coturnix-quail-table-egg",
        route: "/product/coturnix-quail-table-egg",
        component: <ProductBaseLayout
            ebayLink={"https://www.ebay.com/itm/166019852747?hash=item26a78de9cb:g:65kAAOSwbmtkQfk~"}
            etsyLink={"https://www.etsy.com/listing/1455473705/button-quail-eggs-free-shipping?click_key=5226f2b1a3ca5fa9e9a6a256c4de40943c3fdb00%3A1455473705&click_sum=1aee1889&ref=shop_home_active_1&frs=1&crt=1&sts=1"}
            title={"Coturnix Quail Table Egg"}
            images={[buttoneggs1]}
            summary={"put summary here"}
        >
            <MKTypography variant="body2" color="text" mb={3}>
                Item spec here
            </MKTypography>
            <MKTypography variant="body3" color="text" mb={3}>
                body3 type
            </MKTypography>
        </ProductBaseLayout>,
        show: false
    },
];

export default routes;
