import buttoneggs from "../../../assets/images/products/buttoneggs.jpg";
import buttonchicks from "../../../assets/images/products/buttonchicks.jpg";
import coturnixeggs from "../../../assets/images/products/coturnixeggs.jpg";
import coturnixchicks from "../../../assets/images/products/coturnixchicks.jpg";
import dogtoy from "../../../assets/images/products/dogtoy.jpg";
import dogpoop from "../../../assets/images/products/dogpoop.jpg";
import feeder from "../../../assets/images/products/feeder.jpg";
import eggcarton from "../../../assets/images/products/eggcarton.jpg";
import custom from "../../../assets/images/products/custom.jpg";

export const productData = [
  {
    title: "Quails",
    description: "Quail Supplies",
    items: [
      {
        image: buttoneggs,
        name: "Button Quail Hatching Egg",
        description: "Button Quail Hatching Egg",
        count: 10,
        route: "/product/button-quail-hatching-egg",
      },
      {
        image: coturnixeggs,
        name: "Coturnix Quail Hatching Egg",
        description: "Coturnix Quail Hatching Egg",
        count: 14,
        route: "/product/coturnix-quail-hatching-egg",
      },
      {
        image: buttonchicks,
        name: "Button Quail Baby Chicks",
        description: "Button Quail Baby chicks",
        count: 14,
        route: "/product/coturnix-quail-baby-chick",
        local: true
      },
      {
        image: coturnixchicks,
        name: "Coturnix Quail Baby chicks",
        description: "Coturnix Quail Baby chicks",
        count: 14,
        route: "/product/coturnix-quail-baby-chick",
        local: true
      },
      {
        image: feeder,
        name: "Quail feeder",
        description: "Quail feeder",
        count: 14,
        route: "/product/coturnix-quail-hatching-egg",
      },
      {
        image: eggcarton,
        name: "Quail egg cartons",
        description: "Quail egg cartons",
        count: 14,
        route: "/product/coturnix-quail-hatching-egg",
      },
    ],
  },
  {
    title: "Dog Supplies",
    description: "30+ components that will help go through the pages",
    items: [
      {
        image: dogtoy,
        name: "Dog Toys",
        description: "Dog Toys",
        count: 4,
        route: "/sections/navigation/navbars",
      },
      {
        image: dogpoop,
        name: "Dog Poop bags",
        description: "Dog Poop bags",
        count: 4,
        route: "/sections/navigation/navbars",
      },
    ],
  },
  {
    title: "Custom Gift",
    description: "50+ Custom Gifts",
    items: [
      {
        image: custom,
        name: "custom",
        description: "custom",
        count: 6
      },
    ],
  },
];
