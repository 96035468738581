import Grid from "@mui/material/Grid";
import MKTypography from "../../../components/MKTypography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {StyledTableCell} from "./DogBoarding";
import CardHeader from "@mui/material/CardHeader";
import MKAvatar from "../../../components/MKAvatar";
import {extraServicesData} from "./data/ExtraServicesData";
import React from 'react'

export default function ExtraService() {
    return <>
        <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            alignItems="center"
            sx={{textAlign: "center", my: 6, mx: "auto", px: 0.75}}
        >
            <MKTypography variant="h2" fontWeight="bold">
                Extra Services
            </MKTypography>
        </Grid>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="right">Services</StyledTableCell>
                        <StyledTableCell align="right">{"XSmall and Small (<30lb)"}</StyledTableCell>
                        <StyledTableCell align="right">{"Medium and Large (<60lb)"}</StyledTableCell>
                    </TableRow>
                </TableHead>
                {extraServicesData.map(({icon, name, smallPrice, largePrice}) => {
                    return <TableBody>
                        <StyledTableCell align="right">
                            <CardHeader
                                avatar={
                                    <MKAvatar src={icon} variant="rounded"/>
                                }
                                title={name}
                            />
                        </StyledTableCell>
                        <StyledTableCell align="right">{smallPrice}</StyledTableCell>
                        <StyledTableCell align="right">{largePrice}</StyledTableCell>
                    </TableBody>
                })}
            </Table>
        </TableContainer>
    </>
}