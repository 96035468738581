import tiger from "../../../../assets/images/tiger.jpg";
import alfie from "../../../../assets/images/Alfie.jpg";
import aoLiAoAtHome from "../../../../assets/images/Aoliaoathome.jpg";
import yoloInBackyard from "../../../../assets/images/Yoloinbackyard.jpg";
import yumiAtHome from "../../../../assets/images/Yumiathome.jpg";
import lokiGiaAtHome from "../../../../assets/images/LokiGiaathome.jpg";
import moanaAtHome from "../../../../assets/images/Moanaathome.jpg";
import dogWalking from "../../../../assets/images/Dogwalking1.jpg";
import sleeping1 from "../../../../assets/images/sleeping1.jpg";
import sleeping2 from "../../../../assets/images/sleeping2.jpg";
import sleeping3 from "../../../../assets/images/sleeping3.jpg";

export const dogImageData = [
    {
        description: "At home",
        images: [moanaAtHome, lokiGiaAtHome, yumiAtHome, aoLiAoAtHome, alfie]
    },
    {
        description: "In backyard",
        images: [yoloInBackyard, tiger]
    },
    {
        description: "Dog walking",
        images: [dogWalking]
    },
    {
        description: "Sleeping",
        images: [sleeping2,sleeping3,sleeping1]
    },
]