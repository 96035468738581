import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import {Link as ScrollLink} from 'react-scroll'
import MKBox from "../../../components/MKBox";
import MKButton from "../../../components/MKButton";
import MKTypography from "../../../components/MKTypography";
import DefaultNavbar from "../../navigation/DefaultNavbar";
import routes from "../../../routes";
import Carousel from "react-material-ui-carousel";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {headerData} from "./headerData";

function HomeHeader() {
    const [firstImgLoaded, setFirstImgLoaded] = useState(false);
    return (
        <>
            <MKBox bgColor="white" shadow="sm" py={0.25}>
                <DefaultNavbar
                    routes={routes}
                    transparent
                    relative
                />
            </MKBox>
            <img
                src={headerData[0].image}
                onLoad={() => setFirstImgLoaded(true)}
                style={{display: "none"}}
                alt=""
            />
            {firstImgLoaded &&
                <Carousel autoPlay={true}
                          indicators={true}
                          navButtonsAlwaysVisible={false}
                          duration={400}
                >
                    {
                        headerData.map(({image, title, description, route}) => {
                            return <MKBox component="header" position="relative">
                                <MKBox
                                    display="flex"
                                    alignItems="center"
                                    minHeight="60vh"
                                    sx={{
                                        backgroundImage: ({
                                                              palette: {gradients},
                                                              functions: {linearGradient, rgba}
                                                          }) => `${linearGradient(rgba(gradients.dark.main, 0.3), rgba(gradients.dark.state, 0.3))}, url(${image})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                }}
                            >
                                <Container>
                                    <Grid container item xs={12} md={7} lg={6} flexDirection="column" justifyContent="center">
                                        <MKTypography
                                            variant="h1"
                                            color="white"
                                            mb={3}
                                            sx={({breakpoints, typography: {size}}) => ({
                                                [breakpoints.down("md")]: {
                                                    fontSize: size["3xl"],
                                                },
                                            })}
                                        >
                                            {title}
                                        </MKTypography>
                                        <MKTypography variant="body1" color="white" opacity={0.8} pr={6} mr={6}>
                                            {description}
                                        </MKTypography>
                                        <Stack direction="row" spacing={1} mt={3}>
                                            {
                                                route ? <MKButton
                                                    component={Link}
                                                    to={route}
                                                    color="white"
                                                >
                                                    Learn more
                                                </MKButton> : <MKButton color="white">
                                                    <ScrollLink to="products" duration={500}
                                                                delay={100} smooth={true}
                                                    >Learn more</ScrollLink>
                                                </MKButton>
                                            }

                                        </Stack>
                                    </Grid>
                                </Container>
                                </MKBox>
                            </MKBox>
                        })
                    }
                </Carousel>}
        </>

    );
}

export default HomeHeader;