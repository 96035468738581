import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React from 'react'

import MKBox from "../../../components/MKBox";
import MKButton from "../../../components/MKButton";
import MKTypography from "../../../components/MKTypography";

function Features() {
    return (
        <MKBox id="features" component="section" py={{ xs: 3, md: 12 }}>
            <Container>
                <Grid container alignItems="center">
                    <Grid item xs={12} lg={5}>
                        <MKTypography variant="h3" my={1}>
                            Read More About Us
                        </MKTypography>
                        <MKTypography variant="body2" color="text" mb={2}>

                        </MKTypography>
                        <MKTypography
                            component="a"
                            href="/about"
                            variant="body2"
                            color="info"
                            fontWeight="regular"
                            sx={{
                                width: "max-content",
                                display: "flex",
                                alignItems: "center",

                                "& .material-icons-round": {
                                    fontSize: "1.125rem",
                                    transform: "translateX(3px)",
                                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                                },

                                "&:hover .material-icons-round, &:focus .material-icons-round": {
                                    transform: "translateX(6px)",
                                },
                            }}
                        >
                            <MKButton>More about us</MKButton>
                        </MKTypography>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Features;